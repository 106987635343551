@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,300,600');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&family=Montserrat&display=swap');

:root{
  --primary: #8a0116;
  --secondary: #5e5e5e;
  --tertiary: #CED0D2;
  --quaternary: #313131;
  --error: #ff4a4a;
}

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

html{
  scroll-behavior: smooth;
  background: var(--tertiary);
}

/* body::-webkit-scrollbar {
  display: none;
} */

body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  display: flex;
  align-items: center;
  justify-content: center;
}


/* ----------NAVBAR--------- */

.NavbarItems{
  background: var(--tertiary);
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8vh;
}

.navbar-logo{
  height: 80px;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
}

.nav-menu{
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 8vw;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: center;

}

.nav-links{
  color: var(--primary);
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-weight: 600;
  font-family: 'Libre Baskerville', serif;
  transition: 0.6s;
}

a.nav-links:hover {
color:#e57373;
}


.fa-bars{
  color: white;
}

.nav-links-mobile {
  display:none;
}

.menu-icon {
  display:none;
}
/* ----------END NAVBAR---------- */

/* ----------PARALLAX AND TITLES---------- */
h2{
  color: var(--primary);
  font-size: 2.5vw;
  font-weight: 600;
  font-family: 'Libre Baskerville', serif;
}

span.subtitle{
  font-family: 'Lora', serif;
  color: var(--quaternary);
  font-size: 1.4vw;
  font-weight: 300;
}

.intro{
  height: 90vh;
  background-image: linear-gradient(to bottom, rgb(207, 207, 207), rgba(49, 49, 49, 0));
}


.intro .introPhoto .introLogo{
 left: 50%;
 top: 40%;
 position: absolute;
 transform: translate(-50%, -50%);
 margin: 0 0;
 height: 12vw;
}

/* ----------END PARALLAX AND TITLES---------- */

/* SERVICES CARDS */

.services{
  height: 100vh;
  background-color: var(--tertiary);
}

.cards{
  padding-top: 20vh;
  display: flex;
}

.flip-card{
background-color: transparent;
perspective: 1000px;
width: 15vw;
height: 60vh;
padding-top: 1vh;
border-radius: 4px;
margin-left: 4vw;
}

.flip-card-inner{
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transform: rotateY(0deg);
}

.flip-card-inner-back{
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back{
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.card-title{
  font-size: 1.4vw;
  font-family: 'Libre Baskerville', serif;
}
.card-base-text{
  font-size: 1vw;
  font-family: 'Montserrat', sans-serif;
  padding: 1vw;
}
.flip-card-front h2 {
  font-family: 'Libre Baskerville', serif;
  font-size: 1.6vw;
  color: black;
} 
.flip-card-front .s1{
  padding-top: 35%;
}
.flip-card-front .s2{
  padding-top: 40%;
}
.flip-card-front .s3{
  padding-top: 12%;
}
.flip-card-front .s4{
  padding-top: 30%;
}
.flip-card-front .s5{
  padding-top: 40%;
}

.flip-card-back {
  transform: rotateY(180deg);
}

.flip-card button, .card button {
  border: none;
  outline: 0;
  padding: 12px;
  color: white;
  background-color: var(--primary);
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 2vh;
  position: absolute;
  bottom:0;
  left:0;
}

.flip-card-front button:hover {
  opacity: 0.7;
}

/* END CARDS */

/* ----------ABOUT US---------- */
.aboutUs{
  height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
}
.aboutUsText{
  width: 50%;
  height: 100%;
  overflow: hidden;
  float: left;
}
.aboutUsText h2{
  margin: 4vw 0vw 2vw 10vw
}
.aboutUsText span{
  width: 50% ;
  font-family: 'Montserrat', sans-serif;
  font-size: 1vw;
  display: block;
}
.aboutUsText h1{
  font-family: 'Montserrat', sans-serif;
  font-size: 1vw;
  font-weight: 300;
  display:inline
}
.aboutUsText #p0{
  margin: 5vw 0vw 2vw 10vw;

}
.aboutUsText #p1{
  margin: 1vw 0vw 2vw 10vw;
}
.aboutusImg{
  width: 50%;
  height: 100%;
  overflow: hidden;
  float:left;
  padding: 5vh 5vh 5vh 5vh;
}
.aboutUsImg img{
  margin: 5vw 10vw 10vw 10vw;
  height: 80vh;
  position: relative;
  filter: grayscale(100%);
}

/* ----------END ABOUT US---------- */

/* ----------CONTACT FORM---------- */

.contactForm{
  width: 100%;
  height: 55vh;
  margin: 0;
  padding: 0;
  transition: all 0.3s ease;
}

.contactForm .inner {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;
}

.contactForm .inner .panel {
  position: relative;
  box-sizing: border-box;
  height: 100%;
  width: 60%;
  float: left;
  transform: skew(-20deg);
}

.contactForm .inner .panel.panel-left {
  margin-left: -15%;
}

.contactForm .inner .panel .panel-content {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%) skew(20deg);
}

.contactForm .inner .panel .panel-content .titleContainer{
  left: 20%;
  top: 40%;
  position: absolute;
  padding: 1.5vw;
  transform: translate(50%, -50%);
}


.contactForm .inner .panel.panel-left .panel-content .image-background {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  opacity: 0.95;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/urbina-casas.appspot.com/o/contactbg.png?alt=media&token=1e8e586e-dab3-47ea-b262-d5522c3223e3');
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 0%;
  transform: translateX(-50%) translateY(-50%);
}

.contactForm .inner .panel.panel-right {
  margin-right: -10%;
  background: #1B1B1B;
}

.contactForm .inner .panel.panel-right .panel-content .form {
  display: block;
  position: absolute;
  margin: 0 auto;
  width: 30vw;
  height: 55vh;
  top: 65%;
  left: 50%;
  transform: translateX(-55%) translateY(-55%);
}

.group { 
  position: relative; 
  margin-bottom: 3.5vh; 
}

.contactForm .inner .panel.panel-right .panel-content .form .group input,textarea {
  font-size: 1vw;
  height: 1vh;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 25vw;
  border: none;
  outline: none;
  border-bottom: 1px solid #B1B1B1;
  color: #B1B1B1;
  background: rgba(0,0,0,0);
  opacity: 0.5;
  transition: 0.2s ease;
}

.contactForm .inner .panel.panel-right .panel-content .form .group input:focus {
  outline: none;
  opacity: 1;
}

.contactForm .inner .panel.panel-right .panel-content .form .group textarea {
  height: 15vh;
  resize:none;
  font-size: 1vw;
}

.contactForm .inner .panel.panel-right .panel-content .form .group textarea::placeholder {
  color: #B1B1B1;
  font-size: 2vh;
  font-weight: normal;
  font-family: 'Open Sans', sans-serif;
  padding: 0px 10px 10px 5px;
}


.contactForm .inner .panel.panel-right .panel-content .form .group label {
  color: #B1B1B1; 
  font-size: 2vh;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 0.5vw;
  top: -1vh;
  opacity: 0.5;
  transition: 0.2s ease all; 
  -moz-transition: 0.2s ease all; 
  -webkit-transition: 0.2s ease all;
  font-family: 'Open Sans', sans-serif;
}

.contactForm .inner .panel.panel-right .panel-content .form .group input:focus ~ label, .contactForm .inner .panel.panel-right .panel-content .form .group input:valid ~ label 		{
  top: -3vh;
  font-size: 2vh;
  color: #B1B1B1;
  opacity: 1;
}

.highlight {
  position: absolute;
  height: 60%; 
  width: 25vw; 
  top: 25%; 
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

input:focus ~ .highlight {
  -webkit-animation:inputHighlighter 0.2s ease;
  -moz-animation:inputHighlighter 0.2s ease;
  animation:inputHighlighter 0.2s ease;
}


@-webkit-keyframes inputHighlighter {
  from { background:#B1B1B1; }
  to 	{ width:0; background:transparent; }
}
@-moz-keyframes inputHighlighter {
  from { background:#B1B1B1; }
  to 	{ width:0; background:transparent; }
}
@keyframes inputHighlighter {
  from { background:#B1B1B1; }
  to 	{ width:0; background:transparent; }
}

.contactForm .inner .panel.panel-right .panel-content .form .submit {
  float: right;
  color: #B1B1B1;
  transition: 0.2s ease;
  background-color: rgb(27, 27, 27);
  font-size: 2.5vh;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
}

.contactForm .inner .panel.panel-right .panel-content .form .submit:hover {
  color: #FAFAFA;
  cursor: pointer;
}

.toast {
  color: #333333;
  position: fixed;
  top: 60%;
  left: 92%;
  margin:auto;
  max-width: 400px;
  padding: 6px;
  background: #cccccc;
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  border: 1px solid #aaaaaa;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  opacity:0;
  visibility: hidden;
  transition: opacity 0.2s, left 0.2s, visibility 0.2s;
}

.toast--visible{
  left: 62%;
  opacity: 1;
  visibility: visible;
}

.toast--success{
  background-color: #26a69a;
  border: 1px solid #26a69a;
}

.toast--failure{
  background-color: #e57373;
  border: 1px solid #e57373;
}



/* ----------END CONTACT FORM---------- */

/* GALLERY */

.gallery{
  background-color: rgb(27, 27, 27);
}

/* END GALLERY */

.footer {
  bottom:0;
  width:100%;
  height:45vh; 
  background: var(--quaternary);
}

.footer .mapouter{
  width: 50%;
  float:left;
  overflow: hidden;
}

.footer .mapouter .gmap_canvas iframe{
  height: 40vh;
  width: 45vw;
  margin-top: 2vh;
  margin-left: 2vw;
  border-radius: 5px;
}

.footer .footer-right-container{
  overflow: hidden;
  width: 50%;
}

.footer .footer-right-container .footer-right-text{
  padding-top: 12vh;
  color: var(--tertiary);
  margin-left: 7vw;
  font-weight: normal;
  font-family: 'Open Sans', sans-serif;
  font-size: 2.5vh;
}


/* image grid styles */

.img-grid{
  margin: 190px,20px,0px,50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr ;
  gap: 3vh;
  padding: 2vw;
}

.img-wrap{
  overflow: hidden;
  height: 0;
  padding: 50% 0;
  position: relative;
  opacity: 0.8;
}

.img-wrap img{
  min-width: 100%;
  min-height: 100%;
  max-width: 150%;
  position: absolute;
  top: 0;
  left: 0; 
}



/* modal styles */
.backdrop{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.8);
  z-index: 2;
}

.img{
  width: 100%;
  height: 100%;
}

.backdrop img{
  display: block;
  max-width: 70vw;
  max-height: 85vh;
  margin: 5vh auto;
  box-shadow: 3px 5px 7px rgba(0,0,0,0.5);
  border: 0.5vh solid white;
  
}

.img img{
  object-fit: cover;
}

.rightSide{
  flex-direction: column;
  float:right;
  width:5vw;
  height: 100vh;
}
.rightSide .closeModal{
  height: 5vh;
  width: 5vw;
}

.rightSide .closeModal .fa-times{
  color: var(--tertiary);
  font-size: 3vw;
  overflow: hidden;
  transition: 0.3s;
  cursor: pointer;
  font-size: 5vw;
}

.fa-chevron-left, .fa-chevron-right {
  font-size: 5vw;
}

.fa-chevron-left {
  margin-top: 50vh;
}

.fa-chevron-right {
  margin-top: 45vh;
}

.leftArrow .fa-chevron-left, .rightSide .rightArrow .fa-chevron-right{
  color: var(--tertiary);
  transition: 0.3s;
}

.leftArrow .fa-chevron-left:hover, .rightSide .rightArrow .fa-chevron-right:hover{
  cursor: pointer;
  color: var(--primary);
}

.leftArrow{
  float:left;
  width:5vw;
  text-align: right;
}

.rightSide .closeModal .fa-times:hover{
  color:var(--primary);
}

.imgContainer{
  overflow: hidden;
  float:left;
  width:70vw;
  margin-left: 10vw;
}

.middle{
  transition: 0.5s ease;
  position: absolute;
  z-index: 5;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  opacity: 0;
}

.middle:hover{
  background: rgba(190, 190, 190, 0.8);
  opacity: 1;
}

.notVisible{
  display: none;
}

.wrap-title, .wrap-text{
  margin: 1vh 0 1vh 1vh;
}

.wrap-title{
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 1.8rem;
}

.wrap-text{
  font-family: 'Open Sans', sans-serif;
  font-size: 1.3rem;
}

@media (min-width: 1025px) and (max-width: 1440px){
  .wrap-title{
    font-size: 1.5vw;
  }
  
  .wrap-text{
    font-size: 1.2vw;
    font-weight: bold;
  }
}







/* ----------MOBILE---------- */


@media (min-width: 1024px) and (max-width: 1025px) and (orientation: landscape){

  h2{
    font-size: 4vw;
  }
  .aboutUs{
    height: 170vh;
  }
  .aboutUsText{
    width: 100%;
    overflow: inherit;
    height: 45%;
  }
  
  .aboutUsText span{
    width: 80vw ;
    font-family: 'Montserrat', sans-serif;
    font-size: 2vw;
  }
  
  .aboutUsText h1{
    font-family: 'Montserrat', sans-serif;
    font-size: 2vw;
    font-weight: 300;
    display:inline
  }
  
  .aboutUsImg{
    overflow: inherit;
  }

  .card-title{
    font-size: 2vw;
  }
  .card-base-text{
    font-size: 1.20vw;
    margin-top: 1vh;
    
  }
  .flip-card-front h2{
    font-size: 2vw;
    
  }

  .middle{
    opacity: 1;
    background: rgba(190, 190, 190, 0.6);
  }

  .wrap-title{
    font-size: 2vw;
  }
  
  .wrap-text{
    font-size: 1.5vw;
    font-weight: bold;
  }

  .img-grid{
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 2vw;
    padding: 3vw;
  }

  

}


/* TABLET PORTRAIT */

@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){

  .nav-links{
    text-align: center;
    font-size: 1.41vw;
    width: 100%;
    display:table;
  }

  .intro .introPhoto .introLogo{
    height: 20vw;
    left: 50%;
   }

  .cards{
    padding-top: 0;
  }

  /* CARDS */
.services{
  height: 50vh;
}


.flip-card{
margin: 3vh auto;
height: 40vh;
}

.card-title{
  font-size: 2vw;
}
.card-base-text{
  font-size: 1.28vw;
  margin-top: 1vh;
  
}
.flip-card-front h2{
  font-size: 2vw;
  
}

  .img-grid{
    grid-template-columns: 1fr 1fr 1fr ;
    gap: 2vw;
    padding: 3vw;
  }

  .middle{
    opacity: 1;
    background: rgba(190, 190, 190, 0.6);
  }

  .wrap-title{
    font-size: 2.5vw;
  }
  
  .wrap-text{
    font-size: 2vw;
    font-weight: bold;
  }

   h2{
    font-size: 5vw;
  }

  .aboutUs{
    height: 170vh;
  }
  .aboutUsText{
    width: 100%;
    overflow: inherit;
    height: 45%;
  }
  
  .aboutUsText span{
    width: 80vw ;
    font-family: 'Montserrat', sans-serif;
    font-size: 3vw;
  }
  
  .aboutUsText h1{
    font-family: 'Montserrat', sans-serif;
    font-size: 3vw;
    font-weight: 300;
    display:inline
  }
  
  .aboutUsImg{
    overflow: inherit;
  }

  .contactForm{
    height: 90vh;
  
  }
  .contactForm .inner {
    overflow: initial;
  }
  
  span.subtitle{
    font-size: 3vw;
  }
  .contactForm .inner .panel{
  width: 100%;
  }
  .contactForm .inner .panel.panel-left{
    height: 20%;
    margin-left: 0%;
  }
  .contactForm .inner .panel.panel-right{
    height: 80%;
    overflow: hidden;
  }
  
  .contactForm .inner .panel.panel-left .panel-content .image-background{
    background-image: none;
  }
  
  .contactForm .inner .panel .panel-content .titleContainer{
    left: 10%;
    top: 20%;
    position: absolute;
    padding: 1.5vw;
    transform: translate(0%, -60%);
  }
  
  .contactForm .inner .panel{
    transform: skew(0deg);
  }
  
  .contactForm .inner .panel .panel-content {
    transform: skew(0deg);
  }
  
  .contactForm .inner .panel.panel-left .panel-content{
    left: 0%;
  }
  
  .contactForm .inner .panel.panel-left .panel-content .image-background{
    left:0%
  }
  
  
  .contactForm .inner .panel.panel-right .panel-content  {
  left:0%
  }
  
  .contactForm .inner .panel.panel-right .panel-content .form {
    width: 50vw;
    height: 70vh;
    left: 40%;
    top: 15%;
    
  }
  
  .contactForm .inner .panel.panel-right .panel-content .form .group input,textarea{
    font-size: 4vw;
    width: 70vw;
  }
  .contactForm .inner .panel.panel-right .panel-content .form .group textarea{
    font-size: 4vw;
  }
  
  .group{
    margin-bottom: 6vh;
  }

  .footer{
    height: 80vh;
    display: block;
  }
  
  
  .footer .mapouter{
    overflow: auto;
    width: 100%;
  }
  
  .footer .mapouter .gmap_canvas iframe{
    height: 40vh;
    width: 90vw;
    padding-top: 2vh;
    border-radius: 5px;
    margin: 0 auto;
    display:block;
  }
  
  .footer .footer-right-container{
    overflow: auto;
    width: 100%;
  }
  .footer .footer-right-container .footer-right-text{
    padding-top: 8vh;
    text-align: center;
    font-size: 4vw;
  }
  
  

}


/* COMMON LANDSCAPE PHONE */

@media (max-width: 823px) and (orientation: landscape){
  .wrap-title{
    font-size: 2vw;
  }
  
  .wrap-text{
    font-size: 1.5vw;
    font-weight: bold;
  }

  .middle{
    opacity: 1;
    background: rgba(190, 190, 190, 0.6);
  }

  .img-grid{
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 2vw;
    padding: 3vw;
  }

  .flip-card{
    width: 15vw;
    height: 70vh;
  }

  .contactForm{
    height: 70vh;
  }

  .contactForm .inner .panel.panel-right .panel-content .form {
    top: 50%;
  }

  .backdrop img{
    display: block;
  
    min-height: 80vh;
    max-width: 70vw;
    max-height: 90vh;
    margin: 5vh auto;
    box-shadow: 3px 5px 7px rgba(0,0,0,0.5);
    border: 0.5vh solid white;
  }
  

}

/* COMMON PORTRAIT PHONE */

@media (max-width: 767px) and (orientation: portrait) {
  
  /* NAVBAR */
  .NavbarItems{
    position: relative;
    
  }

  .nav-menu{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top:10vh;
    opacity: 1;
    transition: all 0.5s ease;
    grid-gap: 25px;
  }

  .nav-menu.active{
    background: var(--tertiary);;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links{
    text-align: center;
    padding: 5vh;
    width: 100%;
    display:table;
  }

  .menu-icon{
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 90%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .menu-icon .fa-bars{
    color: var(--primary);
  }
  .menu-icon .fa-times{
    color: var(--primary);
  }
  
  /* END NAVBAR */

  /* ----------PARALLAX AND TITLES---------- */

  h2{
  color: var(--primary);
  font-size: 7vw;
  font-weight: 300;
  }

  span.subtitle{
  color: var(--quaternary);
  font-size: 4vw;
  font-weight: 300;
  }

  .parallax{
  height: 300px;
  background-image: linear-gradient(to bottom, rgb(207, 207, 207), rgba(0, 0, 0, 0))
  }

  .titleContainer{
  left: 30%;
  top: 30%;
  position: absolute;
  padding: 20px;
  transform: translate(-50%, -50%);
  }

  .intro .introPhoto .introLogo{
    height: 20vw;
    left: 50%;
   }

/* ----------END PARALLAX AND TITLES---------- */

/* CARDS */
.services{
  height: 330vh;
}

.cards{
  display: block;
  padding-top:0vh;
}

.flip-card{
margin-top: 3vh;
width: 80vw;
margin: 3vh auto;
}

.card-title{
  font-size: 7vw;
}
.card-base-text{
  font-size: 4vw;
  margin-top: 5vh;
  position: absolute;
}
.flip-card-front h2{
  font-size: 7vw;

}

/* END CARDS */

/* IMAGE GRID */

.img-grid{
  grid-template-columns: 1fr 1fr;
  gap: 2vw;
  padding: 3vw;
}

.middle{
  opacity: 1;
  background: rgba(190, 190, 190, 0.6);
}

.wrap-title{
  font-size: 4.5vw;
}

.wrap-text{
  font-size: 3vw;
  font-weight: bold;
}

/* END IMAGE GRID */

/* MODAL MOBILE GALLERY */

.backdrop img{
  
  margin: 50% auto;
  display: block;

  margin: 5vh auto;
  box-shadow: 3px 5px 7px rgba(0,0,0,0.5);
  border: 0.5vh solid white;
}


.imgContainer{
  overflow: hidden;
  float:left;
  width: 80vw;
  margin-left: 5vw;
}


/* END MODAL GALLERY */

/* ABOUT US */
.aboutUs{
  height: 180vh;
}
.aboutUsText{
  width: 100%;
  overflow: inherit;
  height: 52%;
}

.aboutUsText span{
  width: 80vw ;
  font-family: 'Montserrat', sans-serif;
  font-size: 4vw;
}

.aboutUsText h1{
  font-family: 'Montserrat', sans-serif;
  font-size: 4vw;
  font-weight: 300;
  display:inline
}

.aboutUsImg{
  overflow: inherit;
}


/* END ABOUT US */

/* CONTACT FORM */

.contactForm{
  height: 90vh;

}
.contactForm .inner {
  overflow: initial;
}


.contactForm .inner .panel{
width: 100%;
}
.contactForm .inner .panel.panel-left{
  height: 20%;
  margin-left: 0%;
}
.contactForm .inner .panel.panel-right{
  height: 80%;
  overflow: hidden;
}

.contactForm .inner .panel.panel-left .panel-content .image-background{
  background-image: none;
}

.contactForm .inner .panel .panel-content .titleContainer{
  left: 10%;
  top: 20%;
  position: absolute;
  padding: 1.5vw;
  transform: translate(0%, -60%);
}

.contactForm .inner .panel{
  transform: skew(0deg);
}

.contactForm .inner .panel .panel-content {
  transform: skew(0deg);
}

.contactForm .inner .panel.panel-left .panel-content{
  left: 0%;
}

.contactForm .inner .panel.panel-left .panel-content .image-background{
  left:0%
}


.contactForm .inner .panel.panel-right .panel-content  {
left:0%
}

.contactForm .inner .panel.panel-right .panel-content .form {
  width: 50vw;
  height: 70vh;
  left: 40%;
  top: 10%;
  
}

.contactForm .inner .panel.panel-right .panel-content .form .group input,textarea{
  font-size: 4vw;
  width: 70vw;
}
.contactForm .inner .panel.panel-right .panel-content .form .group textarea{
  font-size: 4vw;
}

.group{
  margin-bottom: 6vh;
}


/* END CONTACT FORM */

/* FOOTER */

.footer{
  height: 80vh;
  display: block;
}


.footer .mapouter{
  overflow: auto;
  width: 100%;
}

.footer .mapouter .gmap_canvas iframe{
  height: 40vh;
  width: 90vw;
  padding-top: 2vh;
  border-radius: 5px;
  margin: 0 auto;
  display:block;
}

.footer .footer-right-container{
  overflow: auto;
  width: 100%;
}
.footer .footer-right-container .footer-right-text{
  padding-top: 8vh;
  text-align: center;
  font-size: 4vw;
}


/* END FOOTER */

}
/* ----------END MOBILE---------- */
